import * as React from 'react';

import { cn } from '@cardo/lib';

type InputLabelProps = {
  className?: string;
  children: React.ReactNode;
};

export function InputLabel({ className, children }: InputLabelProps) {
  return (
    <label
      className={cn(
        'uppercase text-xs font-semibold tracking-wider text-gray-500 mb-1',
        className
      )}
    >
      {children}
    </label>
  );
}
